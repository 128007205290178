import {  action, extendObservable} from 'mobx';
import PaginationStore from './paginationStore';

class ErrorHandelingStore extends PaginationStore {
    store;

  constructor(store) {
    
    super()
    this.store = store;
    extendObservable(this, {
        errorMessage: "",

      });
    }
    resetViewErrorMessage  = action(() => {
        this.errorMessage = ""
      })

    setViewErrorMessage = action(data => {
        this.errorMessage = data
      })


}
export default ErrorHandelingStore


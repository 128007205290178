import {   extendObservable} from 'mobx';
import PaginationStore from './paginationStore';

class RssFeedStore extends PaginationStore {
    store;

  constructor(store) {
    
    super()
    this.store = store;
    extendObservable(this, {
      feedarticles: [],
        response: {message:""},
        isPage: false,

      });
    }
  
    setFeed = (data => {
        this.feedarticles = data 
    })
  
    getAllFeeds(page=0, size=10) {
      return this.store.apiStore.get({
        url: `/rssfeed/feed`, 
        params: { page, size }, // Add pagination parameters here
        headers:{'accept': 'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
          this.setFeed(data)
      })
      .catch(err => 
        console.error('Error:', err)
        //err.json().then(data => Promise.reject(data?.detail))
      )
    
    }


    
}
export default RssFeedStore







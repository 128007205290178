

import APIStore from "./apiStore";
import AuthStore from "./authStore";
import ArticleStore from "./articleStore";
import CoursesStore from "./courseStore";
import RssFeedStore from "./rssfeedStore";
import ErrorHandelingStore from "./errorHandelingStore";
class AppStore {
    APIStore;
    AuthStore;
    ArticleStore;
    CoursesStore;
    RssFeedStore;
    ErrorHandelingStore;
    constructor(){
        
        this.apiStore = new APIStore(this);
        this.authStore = new AuthStore(this);
        this.articleStore = new ArticleStore(this);
        this.coursesStore = new CoursesStore(this);
        this.rssFeedStore = new RssFeedStore(this);
        this.errorHandelingStore = new ErrorHandelingStore(this);

    }

}
export default AppStore;
import React, {useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import HeaderComponent from '../../components/Header/HeaderComponent';
import ArticleComponent from '../../components/Article/ArticlesComponent';


const DashboardPage  = inject('store')(observer(({ store , page}) => {

  const save_article = (article) =>{
    store.articleStore.setFeedData(article)
    store.articleStore.createNewArticle();
  }
 
  useEffect(() => {
    store.rssFeedStore.getAllFeeds()
  }, [ store.rssFeedStore ]);


  return (

    <>   
        <HeaderComponent />
    

    <div className="container">
        <div className="sidebar sidebar-left"></div>    
            <ArticleComponent save_article={save_article}articles={store.rssFeedStore.feedarticles}></ArticleComponent>
        <div className="sidebar sidebar-right"></div>   
    </div>

  </>

  );
}));
export default DashboardPage;

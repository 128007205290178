import React from 'react';


import {isAuthenticated} from '../../pages/authentication/isAuthenticated';



const ArticleComponent = (prop) => {

    

  // Helper function to check if a URL is a video
  const isVideoUrl = (url) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    const extension = url?.split('.').pop().toLowerCase();
    return videoExtensions.includes(`.${extension}`);
  };

 
 const decodeHtmlEntities = (str) => {
 
  return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }


  const articles = prop.articles.map((article, index) => {
   
    if(article === "No new articles found!"){
      return <div key={index}>{article}</div>
    }else{

   
    const { id, title, subtitle, img_url, image_alt, content, date, hero_img_url,guid, source_url, author } = article || {};
    let data = {
        id:id || '', 
        title:title || '', 
        subtitle:subtitle || '' , 
        img_url:img_url || '', 
        hero_img_url:hero_img_url || '', 
        author:author || '', 
        content:content || '',  
        source_url: source_url || '', 
        image_alt:image_alt || '',  
        guid:guid || 0, 
        status: 0
    } 
   


    return (
      <div 
      className={"article"} 
      key={index}
      >
        <h1><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}
        {img_url && <img src={img_url} alt={image_alt || "bild"} />}
        {hero_img_url && isVideoUrl(hero_img_url) && (
          <div className="video-container">
            <video controls="controls" preload="metadata" style={{ width: '100%', height: 'auto', maxHeight:'100vh',marginTop:'20px'}}>
              <source src={`${hero_img_url}#t=0.1`} type={`video/${hero_img_url.split('.').pop()}`} />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(content) }} />

        <div className="date">{date}</div>
        {isAuthenticated && <button onClick={()=>{ prop.save_article(data) }}> Save Article </button>} 
        <div>{guid}</div>
  
      </div>
    );
  }
  });

  return <div className='main'> 
  
            {articles} 

  </div>;
};

export default ArticleComponent;
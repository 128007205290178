import {  action, extendObservable} from 'mobx';
import PaginationStore from './paginationStore';


class ArticleStore extends PaginationStore {
    store;

  constructor(store) {
    
    super()
    this.store = store;
    extendObservable(this, {
        articles: [],
        formData: this.getDefaultFormData(),
        response: {message:""},
        isPage: false,

      });
    }
    getDefaultFormData() {
      return {
        id: '',
        title: '',
        subtitle: '',
        img_url: '',
        hero_img_url: '',
        author: '',
        content: '',
        source_url: '',
        image_alt: '',
        guid:1,
        status:0
      };
    }
    resetFormData = action((data)=>{
      this.formData = data
    })

    setFeedData = action(data => {

      this.formData =  data
  
    })

    setFormData = action(data => {

      const { name, value } = data
      if(name === 'status'){
       
        this.formData[name] = Number(value) === 1 ? 0 : 1 
      }else{
        this.formData[name] = value 
      }
      

    })
  

    setFormDataFromSingleArticle = action(data => {
  
      this.formData = {
        title: data.title || '',
        subtitle: data.subtitle || '',
        img_url: data.img_url || '',
        hero_img_url: data.hero_img_url || '',
        author: data.author || '',
        content: data.content || '',
        source_url: data.source_url || '',
        image_alt: data.image_alt || '',
        guid: data.guid || 0,
        status: data.status || 0 
      };
    })

    setIsPage = action(data => {
      this.isPage = data
    })


    
    setArticles = (data => {
      if (this.isPage) {
        this.articles = data
      }else{

        this.articles = [...this.articles, ...data] 
      

      }
      
    })


    setResponse = action(data => {
      this.response = data
    })


    getSingleArticle(id) {

      return this.store.apiStore.get({
        url: `/article/articles/${id}`, 
        headers:{'accept': 'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
          this.setFormDataFromSingleArticle(data)
      })
      //.catch(err => err.json().then(data => Promise.reject(data?.detail)))
      .catch(err => {
        console.log(err)
        //this.setViewErrorMessage(err)
      }
    )
    }


    getAllArticles(page = 0, size = 10) {
      return this.store.apiStore.get({
        url: `/article/articles`, 
        params: { page, size }, // Add pagination parameters here
        headers: { 'accept': 'application/json' },
        auth_headers: this.store.authStore.getAuthHeader()
      })
        .then(response => {
          if (!response.ok) {
            // If the response is not OK, parse the error and throw it
            return response.json().then(data => {
              throw new Error(data?.detail || 'An error occurred while fetching articles');
            });
          }
          return response.json();
        })
        .then(data => {
          // Process the response data
          this.setArticles(data['data']);
          this.setPage(data['page']);
          this.setSize(data['size']);
        })
        .catch(err => {
          if (err instanceof Response) {
            // Handle fetch Response errors
            err.json()
              .then(data => {
                console.error('Error from server:', data?.detail);
                // this.store.errorHandeling.setViewErrorMessage(data?.detail || 'Server returned an error');
              })
              .catch(() => {
                console.error('Failed to parse error response');
                //this.store.errorHandeling.setViewErrorMessage('Failed to parse error response');
              });
          } else {
            // Handle non-fetch errors
            console.error('Unexpected error:', err.message || err);
            //this.store.errorHandeling.setViewErrorMessage(err.message || 'Unexpected error occurred');
          }
        });
    }
    
   


    createNewArticle() {
      return this.store.apiStore.post({
        url: `/article/article`, 
        body: JSON.stringify( this.formData )  ,
        headers:{'accept': 'application/json', 'Content-Type':'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
        this.setResponse(data)
        this.resetFormData(this.getDefaultFormData())
      })
      .catch( err => {

          console.log(err)
        //err => err.json().then(data => Promise.reject(data?.detail))
      
      })
    }

    deleteArticle(id) {

     return this.store.apiStore.delete({
        url: `/article/article/${id}`, 
        headers:{'accept': 'application/json', 'Content-Type':'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
        this.setResponse(data)
        this.resetFormData(this.getDefaultFormData())
      })
      //.catch(err => err.json().then(data => Promise.reject(data?.detail)
     //))
      .catch(err => {
        console.log(err)
        //this.setViewErrorMessage(err)
      }
    )


    }


    updateArticle(id) {
      return this.store.apiStore.put({
        url: `/article/article/${id}`, 
        body: JSON.stringify(this.formData),
        headers:{'accept': 'application/json', 'Content-Type':'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
        this.setResponse(data)
        this.resetFormData(this.getDefaultFormData())
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)
      ))
           .catch(err => {
        console.log(err)
        //this.setViewErrorMessage(err)
      }
    )
    }
    
}
export default ArticleStore







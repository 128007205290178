import React, { useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import TextEditor from '../text/TextEditor';

window.editor_ref = null;

const InputFormComponent = inject('store')(observer(({  store , id}) => {
 

  useEffect(() => {
    if (id !== undefined) {
        store.articleStore.getSingleArticle(id);
    }
}, [id, store.articleStore]);



  const handleChange = (e) => {
   
    store.articleStore.setFormData(e.target)
  };

  const handleContentChange = (value) => {

    store.articleStore.setFormData({ name: 'content', value: value });
};
  const handleFocus = (e) => { 
    e.preventDefault();
    store.articleStore.setResponse({"message":""})
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    if (id !== undefined) {
      store.articleStore.updateArticle(id)
    }else{
      store.articleStore.createNewArticle();
    }

  };

  return (
    <form onSubmit={ handleSubmit } className="form-container">
      <h1> { store.articleStore.response?.message || store.articleStore.response?.error } </h1>
   
      <div className="form-group">
        <label htmlFor="title">Title:</label>

        <input type="text" id="title" name="title" value={store.articleStore.formData.title} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="subtitle">Subtitle:</label>
        <input type="text" id="subtitle" name="subtitle" value={store.articleStore.formData.subtitle} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="img_url">Image URL:</label>
        <input type="text" id="img_url" name="img_url" value={store.articleStore.formData.img_url} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="image_alt">Image alt:</label>
        <input type="text" id="image_alt" name="image_alt" value={store.articleStore.formData.image_alt} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="hero_img_url">Hero Image URL:</label>
        <input type="text" id="hero_img_url" name="hero_img_url" value={store.articleStore.formData.hero_img_url} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="author">Author:</label>
        <input type="text" id="author" name="author" value={store.articleStore.formData.author} onChange={handleChange} onFocus={handleFocus}/>
      </div>
      <div className="form-group">
        <label htmlFor="content">Content:</label>
   


        <TextEditor 

        value={ store.articleStore.formData.content } 
        onChange={handleContentChange} 
        
        />
  
   
      </div>
      <div className="form-group">
        <label htmlFor="source_url">Source URL:</label>
        <input type="text" id="source_url" name="source_url" value={store.articleStore.formData.source_url} onChange={handleChange} onFocus={handleFocus}/>
      </div>



      <div className="switch_button">
          <label className="switch">
            <input
              type="checkbox"
              name="status"
              value= {Number(store.articleStore.formData.status)}
              checked={Number(store.articleStore.formData.status) === 1}
              onChange={ handleChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      
   

      <button type="submit" className="submit-button">Submit</button>
    </form>
  );
}));

export default InputFormComponent;

